.heading1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  color: #212224;
  letter-spacing: 0.8px;
}

.heading2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  color: #212224;
  letter-spacing: 0.8px;
}

.heading3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  color: #212224;
  letter-spacing: 0.8px;
}

.heading4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  color: #212224;
  letter-spacing: 0.8px;
}

.title1 {
  font-size: 22px;
  font-weight: 500;
  line-height: 140%; /* 30.8px */
  color: #212224;
}

.title2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 140%; /* 30.8px */
  color: #212224;
}

.title3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 140%; /* 30.8px */
  color: #212224;
}

.title4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 140%; /* 30.8px */
  color: #212224;
}

.body1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 19.2px */
  color: #212224;
  letter-spacing: 0.64px;
}

.body2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.2px */
  color: #212224;
  letter-spacing: 0.64px;
}

.body3 {
  font-size: 12px;
  font-weight: 400;
  line-height: 140%; /* 19.2px */
  color: #212224;
  letter-spacing: 0.64px;
}

// Font Color
.red {
  color: #eb0a1e;
  // Toyota Red
}

.grey {
  color: #58595b;
}

.blue {
  color: #5bb0e1;
}

// divider
hr {
  height: 1px;
  background-color: #e7e8eb;
  border: none;
}

// text-align
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

// ellipsis
.text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 限制顯示的行數 */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}
