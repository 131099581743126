// 手機 --------------------------------
.form-a {
  .adm-list-default .adm-list-body,
  .adm-list-item-content,
  .adm-checkbox,
  .adm-list-item-content-main,
  .adm-list-item.adm-form-item-vertical,
  .adm-list-item-content-main .adm-list-item-content-main {
    padding: 0;
    border-top: none;
    border-bottom: none;
  }

  .adm-list-item-content-main {
    margin-bottom: 16px;
  }

  .no-mb .adm-list-item-content-main {
    margin-bottom: 0;
  }

  .adm-list-item-content-extra button {
    top: 7px;
  }

  .adm-form-item-has-error .adm-list-item-content-extra button {
    top: -5px;
  }

  .adm-form-item-label {
    color: #212224;
  }
}
// 傳送驗證碼
.mobile {
  .send-verify-code {
    padding: 12px;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    letter-spacing: 0.64px;
    background-color: #212224;
    border: 0;
    border-radius: 8px;

    a {
      color: inherit;
    }
  }

  .send-verify-code.countdown-active {
    color: #77797c;

    //  倒计时激活时的样式
    background-color: #fff;
  }
}

// 後台 --------------------------------
.form--flex1 {
  display: 'flex';
  justify-content: 'left';
}

.form-row {
  display: flex;
  align-items: end;
}
