// 定义变量
$button-height: 48px;
$button-radius: 8px;
$button-font-size: 16px;
$button-font-weight: 500;
$button-letter-spacing: 0.64px;
// 定义一个混合来应用共通的样式
@mixin button-style {
  height: $button-height;
  border-radius: $button-radius;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  letter-spacing: $button-letter-spacing;
  color: #fff; // 大多数按钮文本颜色为白色
}

// 手機 --------------------------------
.mobile {
  .adm-button {
    &.primary-button {
      @include button-style;
      background: #e60012;
    }

    &.light-button {
      @include button-style;
      background: #fdebec;
      color: #e60012;
    }

    &.cancel-button {
      height: 48px;
      border-radius: 8px;
      font-size: 16px;
      letter-spacing: 0.64px;
      display: none;
    }

    &.secondary-button {
      @include button-style;
      background: #212224;
    }

    &.disable-button {
      @include button-style;
      background: #61656b;
    }

    &.empty-button {
      height: 40px;
      font-size: $button-font-size;
      font-weight: $button-font-weight;
      letter-spacing: $button-letter-spacing;
      color: #212224;
    }

    &.line-button {
      @include button-style;
      background: #06c755;
      padding-left: 40px;
      padding-right: 40px;
    }
    &.send-to-line-button {
      padding: 8px 12px;
      border-radius: 40px;
      border: 1px solid #06c755;
      font-size: $button-font-size;
      font-weight: $button-font-weight;
      letter-spacing: $button-letter-spacing;
      color: #06c755;
      background: #fff;
    }
  }
}
