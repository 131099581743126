// 手機 ---------------------------------------------------
// 用於清除自動填充輸入框後的背景色：
/* 针对Chrome、Safari等WebKit内核浏览器 */
.mobile {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: #212224 !important;
  }

  /* 针对Firefox */
  input:-moz-autofill,
  input:-moz-autofill:hover,
  input:-moz-autofill:focus,
  input:-moz-autofill:active {
    background-clip: content-box !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  /* 其他可能需要的样式调整 */
  input:-internal-autofill-selected {
    appearance: none !important;
    background-color: transparent !important; /* 尝试设置为透明 */
    color: -internal-light-dark(black, white) !important;
  }
}
// ---------------------------------------------------------
