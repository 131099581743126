.standard-section-card {
  margin-bottom: 24px;
  //   border-radius: 2px;
}

.standard-section-collapse {
  margin-bottom: 24px;
  background-color: #fff;
  border: 1px solid #f0f0f0;
  //   border-radius: 2px;

  .ant-collapse-item {
    border: none;
  }

  .ant-collapse-header {
    font-weight: 600;
  }

  .ant-collapse-header-text {
    padding-left: 8px;
  }

  .ant-collapse-content {
    border-top: 1px solid #f0f0f0;
  }
}
