// 菜單容器
@media screen and (min-width: 800px) {
  .menu-container {
    width: 800px;
    margin: auto;
  }
}

// 品項容器
.item-container {
  padding: 16px 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
}

.info-container {
  min-width: 328px;
  max-width: 420px; /* Set the maximum width */
  padding: 24px 20px;
  margin: 0 auto; /* Center the container within its parent */
  text-align: left;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
}
