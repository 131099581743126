// 手機 --------------------------------
// 綁定失敗跳窗
.mobile {
  .fail-modal-content {
    display: inline-flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 4px 12px;
    text-align: center;
  }

  .fail-modal-text {
    padding: 0 24px;
  }

  .goto-survey-modal-content {
    font-size: 16px;
    color: #212224;
    text-align: center;
  }
}

// 後台 --------------------------------
.form-in-modal {
  max-height: 400px;
  padding: 16px;
  overflow: hidden auto;
}

.common-modal {
  .common-modal-form {
    overflow: hidden;
  }

  .ant-modal-content {
    padding: 0;
  }

  .ant-modal-header {
    padding: 16px 24px;
    margin: 0;
    box-shadow: 0 -1px 0 0 #f0f0f0 inset;
  }

  .ant-modal-body {
    padding: 0;
  }

  .common-modal-content {
    max-height: 560px;
    padding: 24px;
    overflow: auto;
  }

  .common-modal-footer {
    padding: 10px 16px;
    box-shadow: 0 1px 0 0 #f0f0f0 inset;
  }

  .ant-modal-footer {
    padding: 10px 0;
    margin: 0;
    box-shadow: 0 1px 0 0 #f0f0f0 inset;
  }

  .ant-form-item {
    margin-bottom: 16px;
  }
}

.confirm-modal {
  .ant-modal-content {
    padding: 32px 32px 24px;
  }

  .ant-modal-confirm-btns {
    margin-top: 24px;
  }
}
