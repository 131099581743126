.loading-component {
  .ant-modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 260px;
    height: 260px;
    transform: translate(-50%, -50%);

    .ant-modal-body {
      display: flex;
      justify-content: center;
      height: inherit;
    }

    div .ant-spin-text {
      width: max-content;
      padding-top: 50%;
    }

    .ant-spin-container {
      padding-top: 20%;
    }
  }
}
