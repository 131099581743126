// 後台 --------------------------------
.Sunday,
.Saturday {
  background-color: rgb(150, 190, 225);
}
.holiday_table
  .ant-table
  .ant-table-container
  .ant-table-tbody
  > tr
  .ant-table-cell {
  padding: 2px;
  text-align: center;
}
