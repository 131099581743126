.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.px-2 {
  padding-right: 8px;
  padding-left: 8px;
}

.px-4 {
  padding-right: 16px;
  padding-left: 16px;
}

.pr-2 {
  padding-right: 8px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-3 {
  padding-left: 12px;
}

.pl-4 {
  padding-left: 16px;
}

.pt-4 {
  padding-top: 16px;
}

.pt-5 {
  padding-top: 20px;
}

.pd-1 {
  padding-bottom: 4px;
}

.pd-4 {
  padding-bottom: 16px;
}

.pd-5 {
  padding-bottom: 20px;
}

.pd-6 {
  padding-bottom: 24px;
}

.pt-10 {
  padding-top: 40px;
}

.pd-10 {
  padding-bottom: 40px;
}
// https://tailwindcss.com/docs/padding
