// 手機 --------------------------------
.mobile {
  .project-table {
    .ant-table-thead > tr > th {
      font-size: 16px;
      color: white; /* 你希望的字体颜色 */
      background-color: #212224; /* 你希望的背景色 */
    }

    .ant-typography {
      font-size: 16px;
      color: #212224;
      text-decoration: underline;
    }

    .ant-table-tbody > tr > td {
      font-size: 16px;
      // font-weight: 500;
      color: #212224;
    }
  }
}
// 後台 --------------------------------
.table--zebra {
  .ant-table-tbody {
    > tr:nth-child(odd) {
      background-color: #f5f5f5;
      // 為了處理fix 所以需要另外加上這個
      // .ant-table-cell {
      //   background-color: #f5f5f5;
      // }
    }
  }
  // antd table column title預設已有粗體
  // .ant-table-column-title {
  //   font-weight: bold;
  //   color: aqua;
  // }
}
