// 手機 --------------------------------
.mobile {
  .large-input {
    height: 48px;
    padding: 8px 16px;
    font-size: 16px;
    color: #212224;
    letter-spacing: 0.64px;
    border: 1px solid #d8dadd;
    border-radius: 8px;
  }

  .ant-form-item-explain-error {
    margin: 4px;
    letter-spacing: 0.64px;
  }

  .extra-icon {
    position: absolute;
    top: 50%;
    right: 16px;
    font-size: 20px;
    color: #a7a9ac;
    cursor: pointer;
    transform: translateY(-50%);
  }

  .error-input {
    height: 48px;
    padding: 8px 16px;
    margin-bottom: 4px;
    border: 1px solid #ff433c;
    border-radius: 8px;
  }
}

.baseline-input {
  font-size: 16px;
  color: #212224;
  border: none; /* 移除边框 */
  border-bottom: 1px solid #d8dadd; /* 添加底线 */
  border-radius: 0; /* 移除圆角 */
}

.baseline-input:focus {
  border-color: #d8dadd; /* 可选：改变底线颜色 */
  box-shadow: none; /* 移除阴影 */
}

.baseline-input:hover {
  border-color: #d8dadd; /* 可选：改变底线颜色 */
  box-shadow: none; /* 移除阴影 */
}

.error-message {
  margin-top: 4px; /* 与上方内容的间距 */
  font-size: 14px; /* 字体大小 */
  color: red; /* 错误信息的颜色 */
}
