$step: 1px;
$limit: 50;

@for $i from 1 through $limit {
  $n: $i * $step;

  @at-root .ml-#{$i} {
    margin-left: $n;
  }
}

@for $i from 1 through $limit {
  $n: $i * $step;

  @at-root .mr-#{$i} {
    margin-right: $n;
  }
}

.m-0 {
  margin: 0;
}

.mt-1 {
  margin-top: 4px;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mt-2 {
  margin-top: 8px;
}

.mx-4 {
  margin-right: 16px;
  margin-left: 16px;
}

.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.ml-3 {
  margin-left: 12px;
}

.mt-4 {
  margin-top: 16px;
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-6 {
  margin-bottom: 24px;
}

.my-6 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mt-8 {
  margin-top: 32px;
}

.mb-30 {
  margin-bottom: 120px;
}
// https://tailwindcss.com/docs/margin
