.page-code {
  margin-bottom: 8px;
  font-size: 14px;
}

.page-header-span {
  font-size: 20px;
  color: black;
  text-decoration: none;
}

.page-header {
  // position: fixed;
  // width: 100%;
  padding: 16px 24px;
  background-color: white;
  // box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
}
