// 後台 --------------------------------
.main-container {
  padding: 24px;
  // background-color: white;
  // background: var(--conditional-page-background, #f0f2f5);
  // border-radius: 10px;
  // @media (min-width: 1800px) {
  //     padding: 24px 80px;
  // }

  .search-area {
    padding: 24px;
    margin-bottom: 24px;
    background-color: white;
    border: 0.5px groove #ddd;
    border-radius: 2px;
    // box-shadow: 0 0 0 0 black;
    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }

  .table-area {
    padding: 24px;
    margin-bottom: 24px;
    background-color: white;
    border: 0.5px groove #ddd;
    border-radius: 2px;
    // box-shadow: 0 0 0 0 black;
    // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}
