// 後台 --------------------------------
.table--zebra {
  .ant-table-tbody {
    > tr:nth-child(odd) {
      .ant-table-cell-fix-right {
        background-color: #f5f5f5;
      }
    }
  }
}
