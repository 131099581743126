.mobile-home {
  display: flex; /* 使用 Flexbox 布局 */
  flex-direction: column; /* 垂直排列子元素 */
  height: 100vh;
  overflow: hidden;

  .home-header {
    position: sticky;
    top: 0;
    // z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
  }

  .home-content {
    flex: 1; /* 占據剩餘的空間 */
    padding: 40px 16px;
    overflow-y: auto;
    text-align: center;
    background-color: #f8f9fa;
  }

  .home-footer {
    padding: 40px 24px;
    text-align: center;
    background-color: #f8f9fa;
  }
}
