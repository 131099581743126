// 手機 --------------------------------
.mobile {
  .adm-input-element {
    font-size: 16px !important;
  }

  .adm-search-bar .adm-search-bar-input-box-icon {
    font-size: 24px !important;
    padding-right: 8px;
  }
}
