// 手機 --------------------------------
.borderbox {
  .large-input {
    height: 48px;
    padding: 8px 16px;
    border: 1px solid #d8dadd;
    border-radius: 8px;
  }
}
// 後台 --------------------------------
.no-wrap {
  white-space: nowrap;
}
