// 後台 --------------------------------
.button--search {
  background-color: #1677ff;

  &:not(:disabled):hover {
    // 鼠標移動到按鈕上時的顏色
    background-color: #4096ff !important;
  }
}
.button--delete {
  background-color: #ff4d4f;

  &:not(:disabled):hover {
    // 鼠標移動到按鈕上時的顏色
    background-color: #fe6769 !important;
  }
}
.button--register {
  background-color: #52c41a;

  &:not(:disabled):hover {
    // 鼠標移動到按鈕上時的顏色
    background-color: #6bc140 !important;
  }
}
.button--download {
  background-color: #002766;

  &:not(:disabled):hover {
    // 鼠標移動到按鈕上時的顏色
    background-color: #324563 !important;
  }
}
.button--disable {
  opacity: 0.5;
}

.button {
  margin-right: 5px;
  margin-left: 5px;
}

.button-group {
  display: flex;
  justify-content: end;
  margin-left: auto;
}
// 按鈕色號說明： antd預設的按鈕是藍色 刪除是danger屬性 是紅色 其餘是自定義
