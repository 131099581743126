.notification-success {
  color: #52c41a;
}

.notification-fail {
  color: #ff4d4f;
}

.notification-info {
  color: #1677ff;
}
