// 手機 --------------------------------
.mobile {
  .space-between {
    display: flex;
    gap: 16px;
    align-items: flex-start;
    align-self: stretch;
    justify-content: space-between;
  }
  // 用在預約紀錄，尤其是預約地點、地址
  .align-horizontally {
    display: grid;
    grid-template-columns: 30% auto; /* 第一列占30%，第二列自适应 */
    gap: 4px; /* 根据需要调整 */
  }

  .align-horizontally > div:last-child {
    overflow: hidden;
  }

  .horizontally {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

// 後台 --------------------------------
.flex {
  display: flex;

  &__direction-column {
    flex-direction: column;
  }
}

.alignitems-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.display-none {
  display: none;
}
