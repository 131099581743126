.home {
  height: 100vh;
  overflow: hidden;

  .menu-loading-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: x-large;
    color: white;
  }

  .home-header {
    position: sticky;
    top: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    line-height: 0;

    .companyname {
      font-size: 18px;
      font-weight: bold;
      color: var(--character-primary-inverse, #fff);
    }

    a {
      color: var(--character-primary-inverse, #fff);
    }
  }

  .home-sider {
    background: var(--conditional-sider-background, #fff);
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);

    .trigger-block {
      padding-left: 24px;
      text-align: left;
      border-top: 1px solid #f0f2f5;
    }
  }

  .home-content {
    height: calc(100vh - 64px);
    overflow-y: auto;
  }

  .cover {
    position: relative;
    width: 100%;
    height: 95%;
  }

  .cover::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 100px 70px #f5f5f5 inset;
  }
}
